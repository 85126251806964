import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Separator = ({ children }) => {
  // return ""
  return (
    <h3
      className="mx-2 mb-6 text-lg border-b text-center text-gray-500"
      style={{
        lineHeight: "0.1em",
      }}
    >
      <span className="px-2 bg-white">{children}</span>
    </h3>
  )
}

const SecondPage = () => (
  <Layout>
    <SEO title="Resume" />

    <Separator>Experience</Separator>

    <section className="flex flex-wrap mb-6">
      <ul className="mb-6">
        {[
          { tool: "HTML5", className: "text-teal-800" },
          { tool: "LESS", className: "text-teal-800" },
          { tool: "Sass", className: "text-teal-800" },
          { tool: "TypeScript", className: "text-green-700" },
          { tool: "React", className: "text-green-700" },
          { tool: "Ember", className: "text-green-700" },
          { tool: "CanJS", className: "text-green-700" },
          { tool: "NextJS", className: "text-green-700" },
          { tool: "D3", className: "text-green-700" },
          { tool: "GraphQL", className: "text-indigo-400" },
          { tool: "JSON:API", className: "text-indigo-400" },
          { tool: "Django", className: "text-indigo-400" },
          { tool: "Express", className: "text-indigo-400" },
          { tool: "TS.ED", className: "text-indigo-400" },
          { tool: "SQS", className: "text-red-500" },
          { tool: "Redis", className: "text-orange-500" },
          { tool: "DynamoDB", className: "text-orange-500" },
          { tool: "Elasticsearch", className: "text-orange-500" },
          { tool: "CouchDB", className: "text-orange-500" },
          { tool: "MongoDB", className: "text-orange-500" },
          { tool: "PostgreSQL", className: "text-orange-500" },
          { tool: "GitHub Actions", className: "text-pink-500" },
          { tool: "CircleCI", className: "text-pink-500" },
          { tool: "Docker", className: "text-pink-500" },
          { tool: "Rancher", className: "text-pink-500" },
          { tool: "Kubernetes", className: "text-pink-500" },
          { tool: "AWS", className: "text-pink-500" },
          { tool: "Terraform", className: "text-pink-500" },
          { tool: "OpenAI", className: "text-black-500" },
          { tool: "Hugging Face", className: "text-black-500" },
        ].map(({ tool, className }) => {
          return (
            <li
              key={tool}
              className={`inline-block mr-1 mb-1 px-1 text-xs border rounded ${className}`}
            >
              {tool}
            </li>
          )
        })}
      </ul>

      {[
        {
          icon: "&#x1f3a8;",
          heading: "Creator",
          description:
            "TypeScript Engineer, Stack Architect, CAD Modeler, Maker, Woodworker, Home Builder",
        },
        {
          icon: "&#9889;",
          heading: "Influencer",
          description:
            "Team Leader, Client/Project Manager, RFC Enthusiast, Core Open-Source Contributor",
        },
        {
          icon: "&#9730;",
          heading: "Provider",
          description:
            "Meticulous PR Reviewer, DX Fanatic, Compulsive Volunteer, Servant Leadership Activist",
        },
        {
          icon: "&#9881;",
          heading: "Connector",
          description:
            "Extreme Programmer, Talent Recruiter, Group Thinker, Happy Hour Native",
        },
        {
          icon: "&#x1f34e;",
          heading: "Teacher",
          description:
            "JavaScript Trainer, Conference Speaker, Agile Advocate, Documentation Artisan",
        },
        {
          icon: "&#x1f680;",
          heading: "Pioneer",
          description:
            "AI Adopter, Failed Startup Founder, Serial Hobbyist, Keno Analyst, MySpace Hacker",
        },
        {
          icon: "&#x1f4e2;",
          heading: "Advisor",
          description:
            "Problem Solver, Technology Consultant, Engineering Mentor, Executive Consigliere",
        },
        {
          icon: "&#10031;",
          heading: "Stimulator",
          description:
            "Idealist, Status Quo Disruptor, End-user Sympathizer, Backlog Curator, Culture Evangelist",
        },
        {
          icon: "&#9878;",
          heading: "Equalizer",
          description:
            "Reliability Junkie, Performance Tester, Maintainability Auditor, Sustainability Empath",
        },
      ].map(({ icon, heading, description }) => (
        <article
          key={icon}
          className="flex w-full mb-4 md:w-1/3 leading-tight md:leading-normal"
        >
          <span
            className="flex-initial w-8 mr-1 text-xl text-center"
            dangerouslySetInnerHTML={{ __html: icon }}
          />
          <div className="flex-1">
            <h4 className="inline mr-2">{heading}</h4>

            <p className="inline text-sm text-gray-600 italic md:block">
              {description}
            </p>
          </div>
        </article>
      ))}
    </section>

    <Separator>Work History</Separator>

    {[
      {
        title: "Engineering Manager, Staff Engineer (current)",
        company: "Red Ventures",
        industry: "Lead Generation",
        duration: "October 2019 - Present",
        description: `
          Architected and developed integral systems for budget management and lead delivery;
          Introduced AI pipelines to engage underserved audiences; Prototyped and tested new experiences to
          increase user engagement. Designed an application framework to increase developer productivity.
          Mentored and coached engineers to improve their skills and progress their careers.
        `,
        tools: [
          "React",
          "TypeScript",
          "GraphQL",
          "Redis",
          "PostgreSQL",
          "OpenAI",
          "Terraform",
          "AWS",
          "DynamoDB",
          "SQS",
          "Lambda",
        ],
      },
      {
        title: "Senior Product Engineer",
        company: "HigherEducation",
        industry: "Lead Generation",
        duration: "June 2018 - October 2019",
        description: `
          Improved performance, reliability, maintainability, and operational
          visibility of the lead-generation platform; Resolved issues accounting for $150k in lost revenue;
          Enabled complex A/B testing capabilities; Introduced isomorphic SSR ad delivery.
        `,
        tools: [
          "React",
          "Express",
          "GraphQL",
          "Redis",
          "PostgreSQL",
          "Rancher",
        ],
      },
      {
        title: "Lead Software Engineer",
        company: "ServiceChannel",
        industry: "Facilities Management",
        duration: "October 2017 - March 2018",
        description:
          "Refocused and reprioritized an adrift product team struggling to release; Stood up a continuous deployment pipeline; Reduced integration testing cycles of dependent products/services; Dramatically improved team communication and collaboration.",
        tools: [
          "Ember",
          "Django",
          "Celery",
          "PostgreSQL",
          "AWS",
          "Jenkins",
          "RHEL",
        ],
      },
      {
        title: "Application Development Lead",
        company: "Ally Bank ",
        industry: "Online Banking",
        duration: "December 2016 - October 2017",
        description:
          "Spearheaded a development culture transformation across the department by highlighting areas of opportunity; Scaled the team, reduced scope, prioritized requests, estimated efforts, managed vendors/partners, trained new-hires, and coordinated release schedules.",
        tools: ["Keynote", "Quality Center", "Ember", "Wireshark", "Jenkins"],
      },
      {
        title: "Project/Open-Source Lead",
        company: "Bitovi",
        industry: "Software Consulting",
        duration: "July 2014 - December 2016",
        description:
          "Helped various companies adopt proven strategies for designing, building, and managing software; Contributed open-source solutions focused on the performance and reliability of key community use-cases; Regularly presented, trained, and evangelized open-source projects.",
        tools: [
          "JavaScript",
          "CanJS",
          "React",
          "QUnit",
          "JSPerf",
          "GitHub",
          "Gitter",
        ],
      },
      {
        title: "Senior JavaScript Engineer",
        company: "Ally",
        industry: "Online Banking",
        duration: "August 2013 - July 2014",
        description:
          "Worked closely with the Developer Advocate to build abstractions that removed arduous compliance overhead from the team's delivery cycle; Transferred to the team building single-page-applications to replace legacy products; Specialized in interactive data visualizations.",
        tools: ["Ember", "D3", "Sass", "Node.js", "Tridion"],
      },
      {
        title: "Developer",
        company: "Perform",
        industry: "Sports Media",
        duration: "February 2011 - August 2013",
        description:
          "Assigned complete autonomy over all user-facing facets of technology; Improvised designs; Developed complex UIs; Introduced innovative features; Met countless fixed delivery dates; Established scalable/maintainable conventions that enabled back-end developersto create consistent UIs.",
        tools: ["Backbone", "LESS", "Zend", "Doctrine", "Perl", "CentOS"],
      },
      {
        title: "Developer",
        company: "Thorlos",
        industry: "Apparel",
        duration: "November 2009 - February 2011",
        description:
          "Got a crash course in marketing, customer relations, and product strategy; Maintained an in-house e-commerce platform directly integrated with the packaging/shipping line; Rebuilt web properties to match new responsive designs.",
        tools: ["JavaScript", "AS3", "CSS", "PHP", "MySQL"],
      },
      {
        title: "Developer",
        company: "Sporting News",
        industry: "Sports Media",
        duration: "August 2008 - November 2009",
        description:
          "Collaborated closely with the Lead Designer to establish a style guide; Partnered with the Lead Back-end Engineer to develop complex UIs; Became the core maintainer of branded properties; Lead development of the market disrupting, next-generation, real-time fantasy platform.",
        tools: ["JavaScript", "CSS", "AS3", "LESS", "PHP", "MySQL"],
      },
      {
        title: "Web Developer",
        company: "Skookum",
        industry: "Software Development Services",
        duration: "July 2007 - August 2008",
        description:
          "Cut my teeth building online portfolios for designers, and setting up e-commerce/CMS-backed websites for their direct clients; Assigned the additional responsibility of managing my client projects directly; Prioritized gaining experience architecting complex UIs.",
        tools: [
          "HTML",
          "CSS",
          "jQuery",
          "PHP",
          "Flash/AS3",
          "WordPress/Joomla",
        ],
      },
    ].map(position => (
      <article key={position.duration} className="mb-5">
        <header className="text-md">
          <h4>
            <strong className="font-medium">{position.title}</strong>{" "}
            <span className="block md:inline">
              <span className="hidden md:inline">&mdash;</span>{" "}
              {position.company}{" "}
              <span className="text-gray-500">
                | <em>{position.industry}</em>
              </span>
            </span>
          </h4>
        </header>
        <p className="mb-2 text-xs uppercase text-gray-600">
          {position.duration}
        </p>
        <div className="flex flex-wrap text-sm">
          <p className="flex-1 mb-1 md:mr-5">{position.description}</p>
          <ul className="flex-initial w-full md:w-1/5">
            {position.tools.map(tool => (
              <li
                key={tool}
                className="inline italic text-gray-500 after:comma-delimiter"
              >
                {tool}
              </li>
            ))}
          </ul>
        </div>
      </article>
    ))}
  </Layout>
)

export default SecondPage
